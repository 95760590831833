
import { arrayOf, nullable, object, oneOfType, shape, string } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    body: oneOfType([string(), nullable()]),
    label: oneOfType([string(), nullable()]),
    partners: arrayOf(
      shape({
        id: oneOfType([string(), nullable()]),
        image: arrayOf(object()),
        link: arrayOf(object()),
      }).loose
    ),
  },
};
